import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@remix-run/react';
import { useScreen } from '~/hooks/useScreen';
import Modal from '~/components/Modal';
import { $showSignInDialog } from '~/stores';
import google from '~/assets/google.svg';

export default function SignInDialog(props: { open: boolean }) {
	const { isMobile } = useScreen();

	const delay = isMobile ? '80ms' : '30ms'; // because of the drawer animation, the animation needs to be a bit slower to see it

	return (
		<Modal open={props.open} onOpenChange={(open) => $showSignInDialog.set(open)}>
			<div className="upEnter">
				<h3 style={{ '--stagger': 0, '--delay': delay } as React.CSSProperties} className="text-2xl font-bold mb-3">
					Sign in to Streamdrops
				</h3>
				<p style={{ '--stagger': 1, '--delay': delay } as React.CSSProperties} className="text-[#9b9cb3] mb-6">
					Continue with
				</p>
				<div className="flex flex-col gap-3 font-bold text-sm upEnter">
					<Link
						to="/auth/google/signin"
						className="flex px-5 py-3 border rounded-sm border-[#090A32] justify-between w-full items-center"
						style={{ '--stagger': 4, '--delay': delay } as React.CSSProperties}
					>
						<div className="flex gap-3">
							<img src={google} alt="Google" height={20} />
							<span>Google</span>
						</div>
						<FontAwesomeIcon icon={faArrowRight} />
					</Link>
				</div>
			</div>
		</Modal>
	);
}
