import { isRouteErrorResponse, Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError, useSearchParams } from '@remix-run/react';
import './tailwind.css';
import './animations.css';
import SignInDialog from './components/SignInDialog';
import { $showSignInDialog } from './stores';
import { useStore } from '@nanostores/react';
import { ToastMessage } from './utils.server';
import { useEffect } from 'react';
import { ExternalToast, toast, Toaster } from 'sonner';

export function Layout({ children }: { children: React.ReactNode }) {
	const showSignInDialog = useStore($showSignInDialog);

	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body>
				<Toaster position="top-right" />
				{children}
				<SignInDialog open={showSignInDialog} />
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}

export default function App() {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const toastMessage = searchParams.get('toast');
		if (toastMessage) {
			const toastMessageObj = JSON.parse(toastMessage) as ToastMessage;
			// Remove the 'toast' parameter from the URL
			searchParams.delete('toast');
			setSearchParams(searchParams);

			// Display the toast message
			let toastCall: (message: React.ReactNode, data?: ExternalToast | undefined) => string | number;
			switch (toastMessageObj.level) {
				case 'info':
					toastCall = toast.info;
					break;
				case 'success':
					toastCall = toast.success;
					break;
				case 'warning':
					toastCall = toast.warning;
					break;
				case 'error':
					toastCall = toast.error;
					break;
				default:
					toastCall = toast.info;
					break;
			}
			toastCall(toastMessageObj.message, {
				position: toastMessageObj.position ?? 'top-center',
			});
		}
	}, [searchParams]);

	return <Outlet />;
}

export function ErrorBoundary() {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			return <NotFoundPage />;
		}
		if (error.status === 500) {
			return <ServerErrorPage />;
		}
	}

	return <ServerErrorPage />;
}

function NotFoundPage() {
	return (
		<div>
			<h1 className="text-xl font-medium mt-20">404 - Not Found</h1>
			<p>Sorry, the page you are looking for does not exist.</p>
			<a href="/" className="text-lg mt-10 hover:underline">
				Go home
			</a>
		</div>
	);
}

function ServerErrorPage() {
	return (
		<div>
			<h1 className="text-xl font-medium mt-20">500 - Internal Server Error</h1>
			<p>Oops! Something went wrong on our end. Please try again later.</p>
			<a href="/" className="text-lg mt-10 hover:underline">
				Go home
			</a>
		</div>
	);
}
